@import "bootstrap/bootstrap";


body {
  padding-top: 5rem;
}

h1 {
  margin-bottom: 40px;
}

.navbar {
  flex-direction: unset;
}

.navbar-brand {
  position: relative;
}

.logo-client-name {
  background: #fff;
  padding: 1px 3px;
  color: #000;
  border-radius: 99px;
  position: absolute;
  font-size: 8px;
  line-height: 1em;
  display: inline-block;
  right: -13px;
  top: -1px;
}

.color-box {
  border-radius: 99px;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-right: 10px;
}